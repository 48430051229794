/** @format */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/tailwind.css";
import Index from "./js/main/index";
import Switcher from "./js/components/Switcher";

import IndexDark from "./js/main/index-dark";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index />} />

        <Route path='/index-dark' element={<IndexDark />} />
      </Routes>
      <Switcher />
    </BrowserRouter>
  );
}

export default App;
