import * as Icon from "react-feather";
export const expertiseData = [
  {
    title: "Learning",
    Icon: Icon.Monitor,
  },
  {
    title: " Developing",
    Icon: Icon.Monitor,
  },
  // {
  //   title: "Mac OS",
  //   Icon: Icon.StopCircle,
  // },

  // {
  //   title: "Cinema",
  //   Icon: Icon.Video,
  // },
  // {
  //   title: "Coffee",
  //   Icon: Icon.Coffee,
  // },
  {
    title: "Music",
    Icon: Icon.Music,
  },
  // {
  //   title: "Games",
  //   Icon: Icon.Watch,
  // },
  {
    title: "Designing",
    Icon: Icon.Box,
  },
  // {
  //   title: "Sports",
  //   Icon: Icon.Target,
  // },
  // {
  //   title: "Painting",
  //   Icon: Icon.PenTool,
  // },
  // {
  //   title: "Reading",
  //   Icon: Icon.Book,
  // },
  // {
  //   title: "Android",
  //   Icon: Icon.Smartphone,
  // },

  // {
  //   title: "Other Activity",
  //   Icon: Icon.Activity,
  // },
];
export const servicesData = [
  {
    title: "Node.js Development",
    desc: "Building scalable server-side applications with Node.js, focusing on performance and efficiency.",
    Icon: Icon.Server,
  },
  {
    title: "Express.js Framework",
    desc: "Experienced in designing RESTful APIs and handling server-side logic with Express.js.",
    Icon: Icon.Cpu,
  },
  {
    title: "TypeScript",
    desc: "Utilizing TypeScript to ensure type safety and improve the maintainability of complex codebases.",
    Icon: Icon.Code,
  },
  {
    title: "MongoDB & Databases",
    desc: "Designing and managing NoSQL databases with MongoDB for highly scalable applications.",
    Icon: Icon.Database,
  },
  {
    title: "API Development",
    desc: "Developing secure and efficient APIs to integrate services and communicate between applications.",
    Icon: Icon.Share2,
  },
  {
    title: "Debugging & Code Optimization",
    desc: "Extensive experience in debugging Node.js applications and optimizing performance.",
    Icon: Icon.Tool,
  },
];
export const project = [
  {
    image: "/images/works/1.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/2.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/3.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/4.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/5.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/6.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/7.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/8.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
];
export const blogData = [
  {
    image: "/images/blog/01_giglink.jpg",
    title: "Giglink: Tailwind CSS NFT Marketplace Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_techwind.jpg",
    title: "Techwind: Tailwind CSS Multipurpose Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_upwind.jpg",
    title: "Upwind: Tailwind CSS Landing Page Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const review = [
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
];
