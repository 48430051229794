/** @format */

import React from "react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className='bg-dark-footer relative text-gray-200 dark:text-gray-200'>
      <div className='py-[30px] px-0 border-t border-slate-800'>
        <div className='container text-center'>
          <div className='grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center'>
            <div className='lg:col-span-3 md:text-start text-center'>
              <h1 className='font-bold text-2xl'>Sadeeq Ullah.</h1>
            </div>
            <div className='lg:col-span-5 text-center mt-6 md:mt-0'>
              {/* <p className='mb-0'>
                © {new Date().getFullYear()} sadeeq. {""} <i className='mdi mdi-heart text-red-600'></i>{" "}
                <Link to='https://shreethemes.in/' target='_blank' className='text-reset'></Link>
              </p> */}
            </div>

            <ul className='lg:col-span-4 list-none md:text-end text-center mt-6 md:mt-0'>
              <li className='inline'>
                <Link
                  to='#'
                  // target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilDribbble width={16} />
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='#'
                  // target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilBehance width={16} />
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='https://www.linkedin.com/in/sadeequk/'
                  target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilLinkedin width={16} />
                  <i className='uil uil-linkedin' title='Linkedin'></i>
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='https://www.facebook.com/sadeequk?mibextid=ZbWKwL'
                  target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilFacebookF width={16} />
                  <i className='uil uil-facebook-f align-middle' title='facebook'></i>
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='https://www.instagram.com/sadeequk8/profilecard/?igsh=MWRocHI4Ym5mdXdmbw=='
                  target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilInstagram width={16} />
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='#'
                  // target='_blank'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilTwitter width={16} />
                </Link>
              </li>
              <li className='inline'>
                <Link
                  to='mailto:sadeequk8@gmail.com'
                  className='btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-amber-500 dark:hover:border-amber-500 hover:bg-amber-500 dark:hover:bg-amber-500'
                >
                  <Unicons.UilEnvelope width={16} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
