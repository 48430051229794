/** @format */

import React from "react";
import { expertiseData } from "../Data/data";
// import CountUp from 'react-countup';
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <section className='relative md:py-24 py-16' id='about'>
      <div className='container'>
        <div className='grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]'>
          <div className='lg:col-span-5 lg:px-8'>
            <div className='relative'>
              <img
                src='/images/ab2.png'
                className='rounded-full shadow-md shadow-gray-200 dark:shadow-gray-800'
                alt=''
              />

              {/* <div className='absolute lg:bottom-40 md:bottom-10 bottom-6 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center'>
                <h6 className='font-semibold'>Backend Developer</h6>
                <span className='text-2xl font-medium text-amber-500 mb-0'>
                  <CountUp start={7} className='counter-value' end={1} duration={2.75} />+
                </span>
                <span className='text-sm text-slate-400'>
                  Year <br /> Experience
                </span>
              </div> */}
            </div>
          </div>

          <div className='lg:col-span-7'>
            <div className='lg:pl-5'>
              <h3 className='mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold'>
                Passionate Backend Developer
              </h3>

              <p className='text-slate-400 max-w-xl text-[15px]'>
                Obviously, I'm a Backend Developer with over 2 years of experience, skilled in all stages of the
                development cycle for dynamic and scalable applications. My expertise lies in designing and building
                efficient, secure server-side systems that optimize performance and enhance user experience. With a
                professional focus on creating reliable and scalable architectures, I strive to deliver top-notch
                backend solutions using the knowledge and experience I've gained throughout my career
              </p>
              <p className='text-slate-400 max-w-xl text-[15px] mt-6'>
                My goal is to build the most efficient and scalable server-side solutions, leveraging all my years of
                experience to deliver reliable, secure, and high-performing applications.
              </p>

              <div className='mt-6'>
                <Link
                  to='#project'
                  className='btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md mt-2'
                >
                  See Work
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container md:mt-24 mt-16'>
        <div className='grid grid-cols-1 pb-8 text-center'>
          <h3 className='mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold'>
            Hobbies & Expertise
          </h3>

          <p className='text-slate-400 max-w-xl mx-auto text-[15px]'>
            In my free time, I explore new technologies, automate workflows, and I'm eager to learn tools that improve
            efficiency and scalability.
          </p>
        </div>

        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]'>
          {expertiseData.map((item, index) => {
            const Icon = item.Icon;
            return (
              <div
                className='flex group shadow shadow-gray-200 dark:shadow-gray-800 dark:hover:shadow-gray-700 items-center p-3 rounded-lg bg-white dark:bg-slate-900'
                key={index}
              >
                <div className='flex items-center  justify-center h-[45px] min-w-[45px] bg-amber-500/10 group-hover:bg-amber-500  text-amber-500 group-hover:text-white text-center rounded-xl transition-all duration-500'>
                  <Icon className='h-5 w-5 mr-4' />
                </div>
                <div className='flex-1'>
                  <h4 className='mb-0 text-[17px] font-medium'>{item.title}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
