/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import CountUp from "react-countup";

export default function HeroOne() {
  return (
    <>
      <section className='relative pt-28 personal-wrapper overflow-hidden bg-amber-500/5' id='home'>
        <div className='absolute inset-0' id='overlay'></div>
        <div className='container'>
          <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]'>
            <div>
              <h4 className='font-bold lg:text-[40px] text-3xl lg:leading-normal leading-normal mb-4'>
                Hey! I'm <br />
                <TypeAnimation
                  sequence={["Sadeeq Ullah", 1000, "Software Engineer", 1000, "Backend Developer", 1000]}
                  wrapper='span'
                  speed={50}
                  className='typewrite text-amber-500'
                  repeat={Infinity}
                />
                <span
                  className='typewrite text-amber-500'
                  data-period='2000'
                  data-type='[ "Sadeeq Ullah", "Software Engineer", "Backend Developer" ]'
                >
                  {" "}
                  <span className='wrap'></span>{" "}
                </span>
              </h4>
              <p className='text-slate-400 max-w-xl'>
                Experienced in designing and building robust, scalable server-side applications, managing databases, and
                optimizing performance across all stages of backend development
              </p>

              <div className='mt-6'>
                <Link
                  to='#'
                  className='btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md'
                  onClick={() => window.open("https://wa.me/+923488597922", "_blank")}
                >
                  Hire Me
                </Link>

                <Link
                  to='/cv/sadeeq.pdf'
                  className='btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md ms-1'
                  target='_blank'
                  download
                >
                  Download CV
                </Link>
              </div>
            </div>

            <div className='relative'>
              <img src='/images/hero/2.png' alt='' />

              <div className='absolute lg:bottom-20 md:bottom-10 bottom-2  p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center'>
                <span className='text-3xl font-medium mb-0'>
                  <span className='counter-value font-bold' data-target='125'>
                    {" "}
                    <CountUp start={7} className='counter-value' end={2} duration={2.75} />
                  </span>
                  +
                </span>
                <h6 className='text-sm text-slate-400 mt-1'>Project Completed</h6>
              </div>

              <div className='absolute lg:top-120 md:top-56 top-48  p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center'>
                <h6 className='font-semibold'>Web Developer</h6>
                <h6 className='text-sm text-slate-400 mt-1'>2+ Years Experience</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
